import React from 'react';
import './DashboardApp.css';
import '../../../assets/vendor/nucleo/css/nucleo.css'
import {isLoggedIn} from "../../../services/user/user.service";
import {Redirect, Route, Switch} from "react-router-dom";
import {NavBar} from "../NavBar/NavBar";
import {Categories} from "../Categories/Categories";
import {ToastContainer} from "react-toastify";
import {Results} from "../Results/Results";
import {Import} from "../Import/Import";
import {Export} from "../Export/Export";
import {CategoryEntries} from "../CategoryEntries/CategoryEntries";
import {Stats} from "../Stats/Stats";
import {Events} from "../Events/Events";

export class DashboardApp extends React.Component {

  render() {
    if (!isLoggedIn()) return <Redirect to="/login"/>;

    return (
      <div className="App">
        <ToastContainer pauseOnHover={false} closeOnClick draggable={false} />
        <NavBar/>
        <Switch>
          <Route exact path="/in" component={Events}/>
          <Route exact path="/in/events/:id/stats" component={Stats}/>
          <Route exact path="/in/events/:id" component={Stats}/>
          <Route exact path="/in/events/:id/entries" component={CategoryEntries}/>
          <Route exact path="/in/events/:id/results" component={Results}/>
          <Route exact path="/in/events/:id/import" component={Import}/>
          <Route exact path="/in/events/:id/export" component={Export}/>
          <Route exact path="/in/events/:id/categories" component={Categories}/>
        </Switch>
      </div>
    );
  }
}
