import {api} from "../../../../services/api/api.service";

export const loadCategory = (id, loading) => api({uri: `/categories/${id}`, loading});
export const saveEntry = ({id, editing, ...entry}) => {
  if (id) {
    return api(
      {
        method: 'patch',
        uri: `/entries/${id}`,
        body: {entry},
        loading: () => {
        }
      })
  } else {
    return api(
      {
        method: 'post',
        uri: `/categories/${entry.category_id}/entries`,
        body: {entry},
        loading: () => {}
      });
  }
}

export const removeEntry = ({id}) => api(
  {
    method: 'delete',
    uri: `/entries/${id}`,
    loading: () => {}
  });