import React, {useEffect, useState} from 'react';
import './CategoryEntries.css';
import {combinedStartListPdf, loadCategories, loadEvent, startListPdf} from "./CategoryEntries.service";
import {Button, Card, Col, Collapse, Form, Row, Table} from "react-bootstrap";
import {CategoryDates} from "../CategoryDates/CategoryDates";
import moment from "moment/moment";
import {DateBadge} from "../DateBadge/DateBadge";
import {DashboardPage} from "../DashboardPage/DashboardPage";
import {Entries} from "./Entries/Entries";
import {LoadingRow} from "../../LoadingRow/LoadingRow";

const uniq = (array) => Object.values(array.reduce((a, c) => (a[c.format()] = c, a), {}));

export const CategoryEntries = ({match}) => {
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState({categories: []});
  const [collapse, setCollapse] = useState();
  const [allDates, setAllDates] = useState([]);
  const [groups, setGroups] = useState([]);
  const [bibMatches, setBibMatches] = useState({});
  const [selectedDates, setSelectedDates] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);

  const updateBibMatches = doWork => setBibMatches(doWork(Object.assign([], bibMatches)));
  const downloadPdf = (e) => startListPdf(e, () => {});
  const downloadCombinedPdf = (e) => combinedStartListPdf(e, () => {});

  function getGroups() {
    loadCategories(match.params.id, () => {}).then(c =>
      setGroups(c.reduce((o, c) => {
        if (!c.group) return o;

        if (!o[c.group]) o[c.group] = {name: c.group, entries: []};

        o[c.group]['name'] += ` - ${c.name}`;
        o[c.group]['entries'] = o[c.group]['entries'].concat(c.entries);

        console.log(o);

        return o;
      }, {})));
  }

  const toggleRacerTable = (id) => {
    const copy = Object.keys(collapse).reduce((o, k) => (o[k] = false, o), {});
    copy[id] = !collapse[id];
    setCollapse(copy);
  };

  useEffect(() => {
    loadEvent(match.params.id, setLoading)
      .then(e => {
        setEvent(e);
        let dates = uniq(e.categories.map(c => moment(c.category_date)));
        const dayToday = dates.filter(d => d.isSame(moment(), "day") );
        if (dayToday.length > 0) {
          setSelectedDates(dayToday);
        } else {
          setSelectedDates(dates);
        }
        setAllDates(dates);
      });

    getGroups();
  }, []);

  useEffect(() => setCollapse(event.categories.reduce((o, c) => (o[c.id] = false, o), {})), [event]);

  useEffect(() => setFilteredCategories(event.categories.filter(c => selectedDates.filter(d => d.isSame(c.category_date)).length > 0).sort((a, b) => (a.name > b.name) ? 1 : -1)), [event, selectedDates]);

  const checkIt = (value, entries, group) => updateBibMatches(e => (e[group] = entries.filter(b => value.toString() !== '' && b.bib_number.slice(-2) === value.toString().slice(-2)).length > 0, e));

  return (
    !event
      ? <div></div>
      : <DashboardPage title={event.name} year={event.year} pageTitle="Entries">
        <Row>
          <Col xs={12} md={6} lg={4}>
            <Card className="bg-default">
              <Card.Header className="bg-transparent">
                <h6 className="text-light text-uppercase ls-1 mb-1">Filter Dates</h6>
                {loading
                  ? <LoadingRow/>
                  : <CategoryDates allDates={allDates} selectedDates={selectedDates} setSelectedDates={setSelectedDates}/>
                }
              </Card.Header>
            </Card>
          </Col>
        </Row>

        {
          loading
            ? <LoadingRow/>
            : filteredCategories.sort((a, b) => {
                  const timeA = a.group.toLowerCase();
                  const timeB = b.group.toLowerCase();

                  if (timeA < timeB) {
                      return -1;
                  } else if (timeA > timeB) {
                      return 1;
                  } else {
                      return 0;
                  }
              }).map((c) =>
              <div className="card" key={c.id}>
                <div className="card-header border-0">
                  <Row className="align-items-center">
                    <Col onClick={() => toggleRacerTable(c.id)}>
                      <h3 className="mb-0"><DateBadge>{moment(c.category_date)}</DateBadge> {c.name} Entries</h3>
                    </Col>
                    <Col className="text-right">
                      <Button
                        className="btn btn-sm btn-primary"
                        onClick={() => downloadCombinedPdf(c)}
                      >
                        Combined Time Start List PDF
                      </Button>

                      <Button
                        className="btn btn-sm btn-primary"
                        onClick={() => downloadPdf(c)}
                      >
                        Start List PDF
                      </Button>

                      <Button
                        className="btn btn-sm btn-primary"
                        aria-controls={`category${c.id}`}
                        aria-expanded={collapse[c.id]}
                        onClick={() => toggleRacerTable(c.id)}
                      >
                        See all
                      </Button>
                    </Col>
                  </Row>
                </div>
                <Collapse in={collapse[c.id]}>
                  <div>
                      <Row><Col>
                          <Row><Col>
                              {
                                  groups[c.group] && groups[c.group].entries.length !== 0 && <> Available: {
                                      Array.from({ length: 99 }, (_, i) => i + 1)
                                          .filter((number) => !groups[c.group].entries.some((entry) => entry.bib_number.endsWith(number.toString().padStart(2, '0'))))
                                          .map(e => e).join(", ")
                                  } </>

                              }
                          </Col></Row>
                        <Form.Control
                          type="text"
                          placeholder="Check Bib Number"
                          onChange={(e) => checkIt(e.target.value, groups[c.group].entries, c.group)}
                          className={bibMatches[c.group] ? 'red-input' : ''}/>
                      </Col></Row>

                    <Table striped hover responsive>
                      <thead className="thead-light">
                      <tr>
                        <th>Bib</th>
                        <th>License Number</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Team</th>
                        <th>Ranking</th>
                        <th></th>
                      </tr>
                      </thead>
                      {collapse[c.id] && <Entries category={c} categories={event.categories} onSave={getGroups}/>}
                    </Table>
                  </div>
                </Collapse>
              </div>
            )}
      </DashboardPage>
  );
};
